<template>
  <!-- content -->
  <div id="Statistics">
    <!-- cont_top -->
    <div class="cont_top">
      <!-- count_top_box -->
      <div class="count_top_box">
        <div class="app_dnld_box">
          <h2 class="count_title">지자체 회원가입 수</h2>
          <p class="count_text">{{ $n(totalClientCnt.all) }}명</p>
          <!-- <span class="android">안드로이드</span>
          <span class="android_count">{{ $n(totalClientCnt.android) }}건</span>
          <span class="ios ml_25">ios</span
          ><span class="ios_count">{{ $n(totalClientCnt.ios) }}건</span> -->
          <span class="android_count"></span>
          <div class="round_box">
            <font-awesome-icon :icon="['fa', 'users']" class="fontawe green" />
          </div>
        </div>
        <div class="member_box_02">
          <h2 class="count_title">일반 회원가입 수</h2>
          <p class="count_text">{{ $n(totalUserCnt.all) }}명</p>
          <span class="android">안드로이드</span>
          <span class="android_count">{{ $n(totalUserCnt.android) }}명</span>
          <span class="ios ml_25">ios</span
          ><span class="ios_count">{{ $n(totalUserCnt.ios) }}명</span>
          <div class="round_box">
            <font-awesome-icon :icon="['fa', 'user']" class="fontawe blue" />
          </div>
        </div>
      </div>
      <!-- //count_top_box -->
    </div>
    <!-- //cont_top -->
    <!-- calendar_box -->
    <div class="calendar_box">
      <h3 class="calendar_on">{{ year }}년 {{ month }}월</h3>

      <!-- <button
        class="button is-small is-primary is-outlined mr-5"
        @click="calendarData(-1)"
      >
        &lt;
      </button>
      <button
        class="button is-small is-primary is-outlined ml-5"
        @click="calendarData(1)"
      >
        &gt;
      </button> -->
      <!-- layer_02select_box -->

      <div class="layer_05select_box">
        <!-- <base-select
          :options="searchTypeOptions"
          v-model="search.type"
          placeholder="선택해 주세요"
          @input="selectChange"
        /> -->
        <!-- <div class="select_arrow_02"></div> -->
        <template>
          <input
            class="dt_base_input"
            @click="visible = !visible"
            :placeholder="year + '년 ' + month + '월'"
            type="text"
            readonly
          />
          <month-picker
            class="month_picker"
            v-show="visible"
            :max-date="new Date()"
            :default-month="new Date().getMonth() + 1"
            v-model="findMonth"
            lang="ko"
            @input="visible = false"
          >
          </month-picker>
        </template>

        <!-- <date-picker
          :popover="{ visibility: 'click' }"
          :min-date="new Date(2013, 0, 1)"
          :max-date="new Date()"
          type="month"
          format="yyyy-MM"
          value-format="yyyy-MM"
          v-model="findMonth"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              :placeholder="year + '-' + month"
              type="text"
              :value="inputValue"
              v-on="inputEvents"
              class="dt_base_input"
              readonly
            />
          </template>
        </date-picker> -->
      </div>
      <!-- //layer_02select_box -->

      <table class="calendar_tbl">
        <colgroup>
          <col style="width: 14.28%" />
          <col style="width: 14.28%" />
          <col style="width: 14.28%" />
          <col style="width: 14.28%" />
          <col style="width: 14.28%" />
          <col style="width: 14.28%" />
          <col style="width: 14.28%" />
        </colgroup>
        <thead class="thead">
          <tr>
            <th v-for="day in days" :key="day">{{ day }}</th>
          </tr>
        </thead>
        <tbody class="tbody">
          <!-- 다음달, 저번달 나오게 하는 버튼 부분 -->
          <!-- <tr class="tbl_box_02">
            <td class="one_day">
              <span class="dt_base_num">1</span>
              <div class="date_box">
                <span class="dnld_date_box">20</span>
                <span class="member_date_box">45</span>
              </div>
            </td>
            <td class="one_day">
              <span class="dt_base_num">2</span>
              <div class="date_box">
                <span class="dnld_date_box">154</span>
                <span class="member_date_box">27</span>
              </div>
            </td>

          </tr> -->
          <tr v-for="(date, idx) in dates" :key="idx" class="tbl_box_02">
            <td
              v-for="(day, secondIdx) in date"
              :key="secondIdx"
              class="one_day"
            >
              <span
                class="dt_base_num"
                :class="{
                  'has-text-info-dark': idx === 0 && day >= lastMonthStart,
                  gray: dates.length - 1 === idx && nextMonthStart > day,
                  'has-text-primary':
                    day === today &&
                    month === currentMonth &&
                    year === currentYear,
                }"
              >
                {{ day }}
              </span>
              <div
                :class="[
                  'count',
                  {
                    'display-none':
                      (idx === 0 && day >= lastMonthStart) ||
                      (dates.length - 1 === idx && day < 7),
                    'has-text-primary':
                      day === today &&
                      month === currentMonth &&
                      year === currentYear,
                  },
                ]"
              >
                <span style="color: #009700" v-if="client[day - 1]">
                  {{ $n(client[day - 1].cnt) }}
                </span>
                │
                <span style="color: #006edb" v-if="user[day - 1]">
                  {{ $n(user[day - 1].cnt) }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- //calendar_box -->
    <div class="bottom_box">
      <span class="under_count app">지자체 회원가입 수</span>
      <span class="under_count members">일반 회원가입 수</span>
      <base-button
        class="fl_right"
        type="download"
        @click.prevent.stop="
          download('statistics?download=xls', '일반회원가입 현황', 'xls')
        "
        :disabled="btnDisabled"
        >{{ downloadText }}</base-button
      >
    </div>
  </div>
  <!-- //content -->
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import monent from "moment";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";
// import BaseSelect from "@/components/core/BaseSelect.vue";
// import VDatePicker from "v-calendar/lib/components/date-picker.umd";
// import VDatePicker from "v-calendar";

export default {
  name: "Dashboard",
  components: {
    //BaseSelect,
    // VDatePicker,
  },
  data() {
    /**
     * data
     */
    return {
      downloadText: "엑셀 다운로드",
      btnDisabled: false,
      totalUserCnt: {},
      user: [],
      totalClientCnt: {},
      client: [],
      visible: false,
      findMonth: "",
      community: {
        items: [],
        fields: [
          {
            key: "key1",
            width: "124px",
            label: "번호",
          },
          {
            key: "key2",
            width: "98px",
            label: "이름",
          },
        ],
      },
      days: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      dates: [],
      currentYear: 0,
      currentMonth: 0,
      year: 0,
      month: 0,
      lastMonthStart: 0,
      nextMonthStart: 0,
      today: 0,
      search: {
        type: "",
      },
    };
  },
  watch: {
    findMonth() {
      this.year = this.findMonth.year;
      this.month = this.findMonth.monthIndex;
      this.calendarData();
      this.fetch();
    },
  },
  created() {
    /**
     * created
     */
    this.$Progress.start();

    console.log(client);
    const date = new Date();
    this.year = date.getFullYear();
    this.month = date.getMonth() + 1;

    this.calendarData();
    this.fetch();
  },
  mounted() {
    /**
     * mounted
     */
    this.$Progress.finish();
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    download(url, name, type, params) {
      // this.downloadText = "다운로드 중";
      // this.btnDisabled = true;
      params = {
        startDate: monent(new Date(this.year, this.month - 1, 1)).format(
          "YYYY-MM-DD"
        ),
        endDate: monent(new Date(this.year, this.month, 0)).format(
          "YYYY-MM-DD"
        ),
        page: this.currentPage,
        size: this.pageSize,
        download: "xls",
      };
      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      }
      url = `${API_URL}${SERVICE_API_URL}/user/statistics-general-register-daily`;
      const url2 = `${API_URL}${SERVICE_API_URL}/user/statistics-client-register-daily`;
      File.download(url, name, type, params);
      File.download(url2, "지자체 회원가입 현황", type, params, (response) => {
        console.log(response);
        // if (response.status == 200) {
        this.btnDisabled = false;
        this.downloadText = "엑셀 다운로드";
        // }
      });
    },
    onMonthChange() {
      // console.log(this.findMonth);
    },
    selectChange(month) {
      if (!this.search.type || month !== this.month) {
        this.month = this.search.type;
        this.calendarData();
      }
    },
    calendarData() {
      // 날자 버튼클릭시 다음달, 저번달 나오게 하는 코딩
      // if (arg < 0) {
      //   this.month -= 1;
      // } else if (arg === 1) {
      //   this.month += 1;
      // }
      // if (this.month === 0) {
      //   this.year -= 1;
      //   this.month = 12;
      // } else if (this.month > 12) {
      //   this.year += 1;
      //   this.month = 1;
      // }

      const [monthFirstDay, monthLastDate, lastMonthLastDate] =
        this.getFirstDayLastDate(this.year, this.month);
      this.dates = this.getMonthOfDays(
        monthFirstDay,
        monthLastDate,
        lastMonthLastDate
      );
    },
    getFirstDayLastDate(year, month) {
      const firstDay = new Date(year, month - 1, 1).getDay();
      const lastDate = new Date(year, month, 0).getDate();
      // this.searchTypeOptions.inday = new Date()
      let lastYear = year;
      let lastMonth = month - 1;
      if (month === 1) {
        lastMonth = 12;
        lastYear -= 1;
      }
      const prevLastDate = new Date(lastYear, lastMonth, 0).getDate();
      return [firstDay, lastDate, prevLastDate];
    },
    getMonthOfDays(monthFirstDay, monthLastDate, prevMonthLastDate) {
      let day = 1;
      let prevDay = prevMonthLastDate - monthFirstDay + 1;
      const dates = [];
      let weekOfDays = [];
      while (day <= monthLastDate) {
        if (day === 1) {
          for (let j = 0; j < monthFirstDay; j += 1) {
            if (j === 0) this.lastMonthStart = prevDay;
            weekOfDays.push(prevDay);
            prevDay += 1;
          }
        }
        weekOfDays.push(day);
        if (weekOfDays.length === 7) {
          dates.push(weekOfDays);
          weekOfDays = [];
        }
        day += 1;
      }
      const len = weekOfDays.length;
      if (len > 0 && len < 7) {
        for (let k = 1; k <= 7 - len; k += 1) {
          weekOfDays.push(k);
        }
      }
      if (weekOfDays.length > 0) dates.push(weekOfDays);
      this.nextMonthStart = weekOfDays[0];
      return dates;
    },

    // XXX 일단 월단위로 검색되도록
    async fetch() {
      let params = {
        startDate: monent(new Date(this.year, this.month - 1, 1)).format(
          "YYYY-MM-DD"
        ),
        endDate: monent(new Date(this.year, this.month, 0)).format(
          "YYYY-MM-DD"
        ),
      };

      await client.generalUserRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          this.user = data.items.reverse();
          this.totalUserCnt = data.totalCnt;
        },
        () => {}
      );

      await client.clientRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          this.client = data.items.reverse();
          this.totalClientCnt = data.totalCnt;
        },
        () => {}
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
